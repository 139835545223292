.navbar{
    height: 100%;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo__container{
    padding: 0 5%;
}

.navlink__container{
    padding: 0 5%;
}

.navlink__wrapper{
    display: flex;
    list-style: none;
}

.nav__item{
    text-align: center;
    white-space: nowrap;
}

.nav__link{
    padding-left: 30px;
    text-align: center;
    text-decoration: none;
    color: #000;
    font-family: Abel;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.75em;
}

@media only screen and (max-width: 768px){
    .navbar{
        flex-direction: column;
        margin-top: 5%;
    }
    .nav__link{
        padding-left: 0px;
        padding: 0 15px;
    }
    .navlink__container{
        width: 100%;
        padding: 0 0;
    }
    .navlink__wrapper{
        justify-content: space-evenly;
        flex-wrap: wrap;
        text-align: center;
    }
}
@media only screen and (max-width: 468px){
    .navlink__wrapper{
        flex-direction: column;
    }
    .nav__link{
        line-height: 50px;
    }
}