.contact{
    width: 100%;
    height: 90vh;
    display: flex;
}

.contact__img{
    width: 47%;
    height: auto;
    margin: 0 0 3% 3%;
    object-fit: cover;
}

.form__container{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact__form{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 75%;
    font-family: Abel;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.25em;
}

.form__title{
    font-size: 4vw;
    margin: 5% 0;
}

.form__row{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}

.row__item{
    margin: 25px 0;
    display: flex;
    flex-direction: column;
}

input[type=text]{
    width: 14em;
    height: 25px;
    margin-top: 5px;
    border-color: transparent;
    background-color: transparent;
    border-bottom: 1px solid #000;
    outline: none;
}

textarea{
    border-color: transparent;
    background-color: transparent;
    border-bottom: 1px solid #000;
    outline: none;
    resize: none;
    padding-top: 20px;
}

.submit{
    display: flex;
    margin: 10% 0;
}

.submit>button{
    font-family: Abel;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.25em;
    background-color: transparent;
    border: none;
}

.submit>button:hover{
    cursor: pointer;
}

.email__link{
    color: rgba(0, 0, 0, 0.37);
    text-decoration: none;
}

.submitSuccess__wrapper{
    width: 90%;
}

.submitSuccess_title{
    font-size: 4vw;
    margin: 5% 0;
    font-family: Abel;
    line-height: 13px;
    letter-spacing: 0.25em;
}

.submitted{
    font-family: Abel;
    font-size: 1rem;
    margin: 20% 0;
}

@media only screen and (max-width: 768px){
    .contact{
        flex-direction: column;
        align-items: center;
        height: auto;
    }
    .contact__img{
        width: 100%;
        height: 30em;
        margin: 0;
    }
    .form__container{
        width: 85%;
        margin: 10% 0;
    }
    .form__title{
        font-size: 5vw;
    }
    .submitSuccess__wrapper{
        width: 70%;
    }
}

@media only screen and (max-width: 468px){
    .contact{
        flex-direction: column;
    }
    .form__container{
        width: 95%;
    }
    .contact__form{
        width: 90%;
    }
    .form__title{
        font-size: 10vw;
    }
    input[type=text]{
        width: 10em;
    }
}

