.home{
    width: 100%;
    height: 100%;
}

.home__header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.home__header>h1{
    font-family: Sanchez;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 64px;
}

.subHeader__wrapper{
    display: flex;
    align-items: center;
}

.subHeader__wrapper>p{
    font-family: Abel;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.75em;
    margin-right: -0.75em;
    margin: 0 20px;
}

.line{
    background-color: #000;
    min-width: 50px;
    height: 1.5px;
    margin: 15px 0;
}

.home__body{
    position: relative;
    height: 80vh;
    width: 100%;
    background-image: url('../Images/home__hero.png');
    background-size: cover;
    background-position: center center;
}

.icons__container{
    position: absolute;
    width: 100%;
    bottom: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon{
    padding: 0 10px;
}

.icon:hover{
    cursor: pointer;
}

