@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Meow+Script&family=Mrs+Saint+Delafield&family=Sacramento&family=Sassy+Frass&display=swap');

.album{
    width: 96%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
    margin: 10% auto;
}

.album__start__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 50vw;
    height: 100%;
}

.album:first-child{
    margin: 5% auto;
}

.album:last-child{
    margin: 5% auto;
}

.album:nth-child(even){
    flex-direction: row-reverse;
}

.album:nth-child(even) .secondaryImage{
    margin-right: 0%;
    margin-left: 5%;
}

.secondaryImage__wrapper{
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
}

.secondaryImage{
    width: 45%;
    height: auto;
    object-fit: cover;
    object-position: center;
    margin-right: 5%;
    border-radius: 5px;
}

.text__container{
    padding: 5% 20px;
    margin: auto;
}

.textLine01{
    font-family: Old Standard TT;
    font-size: .8rem;
}

.textLine02{
    font-family: Old Standard TT;
    font-size: 3rem;
    line-height: 74px;
    text-decoration: underline;
}

.textLine03{
    width: 275px;
    font-size: 1rem;
    line-height: 17px;
    padding: 15px 0;
}

.textLine04{
    font-family: Mrs Saint Delafield;
    font-size: 1.5rem;
}

.flex{
    display: flex;
    align-items: center;
    /* white-space: nowrap; */
}

.album__end__wrapper{
    position: relative;
    width: 50vw;
    height: auto;
}

.primaryImage{
    position: absolute;
    width: 100%;
    max-height: 110%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;

}

@media only screen and (max-width: 768px){
    .textLine01{
        font-size: .5rem;
    }
    .textLine02{
        font-size: 2.5rem;
        line-height: 45px;
    }
    .textLine03{
        font-size: .7rem;
        width: 100%;
    }
    .textLine04{
        font-size: 1rem;
    }
}

@media only screen and (max-width: 468px){
    .album{
        flex-direction: column;
        align-items: center;
        margin: 15% auto;
    }
    .album:nth-child(even){
        flex-direction: column;
        align-items: center;
    }
    .album__start__wrapper{
        width: 90vw;
    }
    .secondaryImage__wrapper{
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
    }
    .album:nth-child(even) .secondaryImage{
        margin-left: 0;
    }
    .secondaryImage{
        width: 49%;
        margin-right: 0;
    }
    .album__end__wrapper{
        width: 90vw;
        height: 100vw;
    }
    .album:last-child{
        margin: 10% auto;
    }
}